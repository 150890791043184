<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <v-card
          elevation="15"
          class="rounded-lg align-center justify-center border-0"
          style="overflow: hidden"
        >
          <!--
          <IVSPlayer :stream="stream" v-if="!waitMode" />
          <v-alert
            v-else
            color="white"
            prominent
            type="info"
            text
            icon="mdi-clock-outline"
            class="ma-0 font-weight-bold"
          >
            The live stream has ended. Thank you for joining us.
          </v-alert>
          -->
          <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe
              src="https://player.vimeo.com/video/704823599?h=790bf9d4f6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="position:absolute;top:0;left:0;width:100%;height:100%;"
              title="Arcelik_OID_Yayin_ENG"
            ></iframe>
          </div>
        </v-card>
        <!--
        <v-row class="mt-5">
          <v-col class="d-flex flex-row justify-center align-center">
            <div class="flex flex-grow text-right white--text text-bold mr-4">
              Stream Language:
            </div>
            <div class="flex flex-grow-0">
              <v-select
                class="flex"
                :items="languages"
                solo
                dense
                prepend-inner-icon="mdi-translate"
                :value="$i18n.locale"
                hide-details
                @input="setLang"
              />
            </div>
          </v-col>
        </v-row>
        -->
        <!--
        <v-toolbar
          dark
          flat
          color="transparent"
          :dense="$vuetify.breakpoint.xsOnly"
        >
          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

          <v-btn-toggle
            v-if="languages.length > 1"
            v-model="selectedLangIndex"
            group
            :dense="$vuetify.breakpoint.xsOnly"
            mandatory
          >
            <v-btn
              v-for="source in languages"
              :key="source.text"
              :small="$vuetify.breakpoint.xsOnly"
            >
              {{ source.text }}
            </v-btn>
          </v-btn-toggle>
        </v-toolbar>
        -->
      </v-col>
      <!--
      <v-col v-if="activeSidebar === 'menu'" cols="12" lg="1">
        <div class="flex lg:flex-col justify-start">
          <div
            class="flex flex-initial lg:w-full lg:flex-col mr-6 lg:mr-0 lg:mb-16 space-x-2 lg:space-x-0 lg:space-y-4"
          >
            <button
              @click="setActiveSidebar('video')"
              class="lg:w-full px-6 bg-gray-900 h-24 hover:bg-gray-800 rounded-md shadow-md flex flex-col justify-center items-center"
            >
              <v-icon dark size="32">
                mdi-video
              </v-icon>
              <div class="text-center font-bold leading-tight">
                Video Question
              </div>
            </button>
            <button
              @click="setActiveSidebar('text')"
              class="lg:w-full px-6 bg-gray-900 h-24 hover:bg-gray-800 rounded-md shadow-md flex flex-col justify-center items-center"
            >
              <v-icon dark size="32">
                mdi-message-text
              </v-icon>
              <div class="text-center font-bold leading-tight">
                Text Question
              </div>
            </button>
          </div>
          <button
            @click="openSurvey"
            :class="
              `flex-initial lg:w-full px-6  h-24 rounded-md  flex flex-col justify-center items-center ${
                enableSurvey
                  ? 'bg-gray-900 hover:bg-gray-800 shadow-md'
                  : 'bg-gray-700 text-gray-400'
              }`
            "
            :disabled="!enableSurvey"
          >
            <v-icon
              dark
              size="32"
              :class="`${enableSurvey ? 'text-white' : 'text-gray-400'}`"
            >
              mdi-chart-box
            </v-icon>
            <div class="text-center font-bold leading-tight">
              Survey
              {{ enableSurvey ? '' : 'closed' }}
            </div>
          </button>
        </div>
      </v-col>
      <v-col v-if="activeSidebar === 'video'" cols="12" lg="3">
        <Viqu />
      </v-col>
      <v-col v-if="activeSidebar === 'text'" cols="12" lg="3">
        <v-card dark class="">
          <v-toolbar flat dense>
            <v-icon class="mr-2">
              mdi-message-text
            </v-icon>
            <v-toolbar-title class="text-base font-bold"
              >Text Question</v-toolbar-title
            >
            <v-spacer />
            <v-btn icon @click="setActiveSidebar('menu')">
              <v-icon class="">
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <AskQuestion />
        </v-card>
      </v-col>
      -->
    </v-row>
    <v-dialog :value="welcomeDialog" width="500">
      <v-card>
        <v-card-text class="pt-5 text-h6 text-center">
          {{ $t('welcome.popup') }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            class="ma-2"
            @click="welcomeDialog = false"
          >
            {{ $t('welcome.proceed') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<route>
{}
</route>

<script>
// import Sidebar from '@/components/sidebar/Sidebar';
// import IVSPlayer from '@/components/IVSPlayer';
// import Plyr from '../../components/Plyr.vue';
// import Survey from '@/components/sidebar/Survey';
//import Viqu from '@/components/sidebar/Viqu';
//import AskQuestion from '@/components/sidebar/qna/AskQuestion';

export default {
  name: 'Stage',
  components: {
    // IVSPlayer,
    // Survey,
    //Viqu,
    //AskQuestion
  },
  head() {
    return {
      title: this.title[this.$route.params.stage]
    };
  },
  data() {
    return {
      activeSidebar: 'menu',
      welcomeDialog: false,
      waitMode: false,
      timeout: null,
      selectedLangIndex: 0,
      plyrOptions: {
        playsinline: true,
        controls: ['play-large', 'play', 'mute', 'volume', 'fullscreen'],
        ratio: '16:9'
      },
      title: {
        main: 'Open Innovation Day'
      },
      languages: [
        { text: 'English', value: 'en' },
        { text: 'Türkçe', value: 'tr' }
      ],
      streams: {
        main: {
          en:
            'https://8eb7d88fae12.eu-west-1.playback.live-video.net/api/video/v1/eu-west-1.832219166033.channel.dvg5GxYV6R0M.m3u8',
          tr:
            'https://8eb7d88fae12.eu-west-1.playback.live-video.net/api/video/v1/eu-west-1.832219166033.channel.jjnNzT6CCjCv.m3u8'
        }
      }
    };
  },
  computed: {
    usr() {
      return this.$store.state.auth?.user;
    },
    enableSurvey() {
      return this.$store.state.settings.enableSurvey;
    },
    selectedLang() {
      return this.languages[this.selectedLangIndex].value;
    },
    stream() {
      if (this.streams[this.$route.params.stage]) {
        return (
          this.streams[this.$route.params.stage][this.selectedLang] ||
          Object.values(this.streams[this.$route.params.stage])[0]
        );
      }
      return null;
    }
  },
  methods: {
    openSurvey() {
      window.open(
        'https://www.questionpro.com/a/TakeSurvey?tt=TGj%2BeNDt6T0%3D',
        '_blank'
      );
    },
    setActiveSidebar(sidebar) {
      this.activeSidebar = sidebar;
    },
    setLang(lng) {
      this.selectedLang = lng;
    }
  },
  created() {
    if (!Object.keys(this.streams).includes(this.$route.params.stage)) {
      this.$router.push(`/stage/${Object.keys(this.streams)[0]}`);
      return;
    }
    if (this.waitMode) {
      this.timeout = setTimeout(() => {
        window.location.reload();
      }, 1000 * 60 * 3);
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
};
</script>

<style></style>
